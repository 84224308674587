<template>
    <section class="section">
      <div class="site-container">
        <div class="requisites-block">
          <h2 class="requisites-block__heading section__heading">Удаление аккаунта</h2>
          <p v-if="!is_delete">Вы уверены что хотите удалить аккаунт?</p>
          <div v-if="!is_delete" style="display:flex; justify-content: space-between">
            <button @click="is_delete=true" class="btn" style="width: 100px;">Да</button>
            <router-link to="/personal" class="btn btn--non-target" style="width: 100px;">Нет</router-link>
          </div>
          <p v-if="is_delete">Ваша заявка на удаление принята</p>
        </div>
      </div>
    </section>

</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  data: () => ({
    is_delete: false
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {

  },
  methods: {

  },
  created() {

  },
}
</script>

