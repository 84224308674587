<template>
  <div class="order-success__info-block info-block info-block--success info-block--centered" v-if="manager">
    <h3>По любым вопросам и для изменения заказа</h3>
    <p>
      Обращайтесь к вашему менеджеру
    </p>
    <a :href="'tel:'+manager.phone_clear">{{manager.phone}}</a>
    <span>{{ manager.name }}</span>
  </div>
</template>
<script>


export default {
  props: [

  ],
  data: () => ({

  }),
  computed: {
    manager() {
      return this.$store.getters.manager;
    }
  },
  components: {

  }
}
</script>