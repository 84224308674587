<template>
    <section class="section">
      <div class="site-container">
        <div class="catalog-filters">
          <div class="catalog-filters__inputs">
            <form action="#" class="catalog-filters__search-form" method="post" @submit.prevent="doSearch">
              <input v-model="search" v-on:change="doSearch" v-on:keyup="doSearch" class="catalog-filters__search input" type="text" placeholder="Поиск по товарам...">
            </form>

            <button @click="openModal" class="catalog-filters__sort-btn"></button>
          </div>

          <div v-if="search.length > 2" class="catalog-filters__results">
            <span class="catalog-filters__results-count">Товаров найдено: {{ searchResult }}</span>

            <button @click="resetSearch" class="catalog-filters__reset-btn">Отменить поиск</button>
          </div>
        </div>

        <Elements :brands="brands"
                  :id="$route.params.id"
                  :sort="sort"
                  :search="search"
        ></Elements>

        <div v-if="searchResult==0" class="no-products">
          <h2 class="no-products__heading">Мы ничего не нашли</h2>

          <div class="no-products__img-block">
            <img class="no-products__img" src="/img/img-products-not-found-new.png" alt="Мы ничего не нашли">
          </div>
        </div>
      </div>
    </section>
  <div class="modal" :class="modalOverlay">
    <div class="modal__body modal__body--open" id="modalFilter" :class="targetModal">
      <button @click="closeModals" class="modal__close-btn"></button>

      <h3 class="modal__heading">Сортировка</h3>

      <div class="modal__body-content">
        <div class="radio">
          <input v-model="sort" value="name_asc" class="radio__input hidden-input" type="radio" name="modal-radios-sort" id="modalRadio1">
          <label class="radio__label" for="modalRadio1">по алфавиту</label>
        </div>

        <div class="radio">
          <input v-model="sort" value="price_asc" class="radio__input hidden-input" type="radio" name="modal-radios-sort" id="modalRadio2">
          <label class="radio__label" for="modalRadio2">сначала дешевые</label>
        </div>

        <div class="radio">
          <input v-model="sort" value="price_desc" class="radio__input hidden-input" type="radio" name="modal-radios-sort" id="modalRadio3">
          <label class="radio__label" for="modalRadio3">сначала дорогие</label>
        </div>

        <div class="radio">
          <input v-model="sort" value="id_desc" class="radio__input hidden-input" type="radio" name="modal-radios-sort" id="modalRadio4">
          <label class="radio__label" for="modalRadio4">сначала новые</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Elements from '../components/app/Elements'

export default {
  data: () => ({
   // id:this.$props.id
    modalOverlay: false,
    targetModal: false,
    sort: 'name_asc',
    search: '',
    searching:false,
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    brands() {
      return this.$store.getters.section(this.$route.params.id);
    },
    searchResult() {
      return this.$store.getters.searchResult();
    }
  },
  watch: {
    sort(val) {
      this.$store.dispatch({type:'fetchSection', id:this.$route.params.id, sort: this.sort})
      this.closeModals();
    }
  },
  async created() {
    // if (this.$store.getters.section[this.$route.params.id] === false || this.$store.getters.section[this.$route.params.id] == undefined)
    await this.$store.dispatch({type:'fetchSection', id:this.$route.params.id})
  },
  components: {Elements},
  methods: {
    openModal() {
      //let body = $('body');


      //body.addClass('fixed');
      this.modalOverlay='modal--open';
      this.targetModal='modal__body--open';

      /*if (itemToRemove !== false) {
        let confirmBtn = targetModal.find('.modal__confirm-btn');

        confirmBtn.on('click', function(e) {
          e.preventDefault();

          itemToRemove.remove();
          closeModals();
        });
      }*/
    },
    closeModals() {
      //let body = $('body');
      //body.removeClass('fixed');
      this.modalOverlay='';
      this.targetModal='';
    },
    resetSearch() {
      this.search = '';
      this.doSearch();
    },
    async doSearch() {
      console.log('doSearch');
      console.log(this.search);
      let val = this.search;
      if (val.length < 3 && val != '')
        return;
      /*if (this.searching == true)
        return;*/
      this.searching = true;
      await this.$store.dispatch({type:'fetchSection', id:this.$route.params.id, sort: this.sort, search: val});
      this.searching = false;
    }
  },
}
</script>

