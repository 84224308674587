<template>
  <main class="main">
    <Slider></Slider>

    <section class="section">
      <div class="site-container">
        <div class="block-links block-links--index">
          <router-link :to="banner_url" class="block-links__link" href="#" :style="banner_image">
            <div class="block-links__link-text">
              <span class="block-links__link-heading">{{ banner_header }}</span>
              <span class="block-links__link-count">{{ banner_text }} {{ declOfNum(banner_text, ['товар','товара','товаров']) }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <New></New>

    <Sale></Sale>


    <section class="section index-company" v-if="about_text || about_image">
      <div class="site-container">
        <h2 v-if="about_header" class="section__heading index-company__heading">{{about_header}}</h2>

        <div v-if="about_text" class="index-company__text">
          <p>
            {{about_text}}
          </p>
        </div>

        <img v-if="about_image" class="index-company__img" :src="about_image" :alt="about_header">
      </div>
    </section>
  </main>
</template>

<script>
import Slider from "../components/app/Slider"
import Sale from "../components/app/Sale"
import New from "../components/app/New"

export default {
  beforeRouteLeave(to, from) {
    if (to.name != 'login')
      this.$store.dispatch('unLoader');
  },
  created() {
    //if (this.$store.getters.homeAbout === false || this.$store.getters.homeAbout == undefined)
      this.$store.dispatch('fetchHomeAbout')
  },
  computed: {
    about_text() {
      return this.$store.getters.about_text;
    },
    about_header() {
      return this.$store.getters.about_header;
    },
    about_image() {
      return this.$store.getters.about_image;
    },
    banner_text() {
      return this.$store.getters.banner_text;
    },
    banner_header() {
      return this.$store.getters.banner_header;
    },
    banner_url() {
      return this.$store.getters.banner_url;
    },
    banner_image() {
      return "background-image: url("+this.$store.getters.banner_image+");";
    }
  },
  mounted() {
   this.$store.dispatch('Loader');
 },
  components: {Slider, Sale, New},
  methods: {
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
}
</script>

