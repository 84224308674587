<template>
  <section class="section">
    <div class="site-container">
      <div class="cabinet-files">
        <div v-if="files.bill || files.ttn" class="cabinet-files__info info-block info-block--success">
          <p>
            Файлы сформированы. Вы можете их скачать.
          </p>
        </div>
        <div v-if="!files.load" class="cabinet-files__info info-block info-block--error">
          <p>
            Формирование файлов...
          </p>
        </div>
        <div v-else-if="files.load && !files.bill && !files.ttn" class="cabinet-files__info info-block info-block--error">
          <p>
            Файлы не найдены.
          </p>
        </div>

        <img class="cabinet-files__img" src="/img/img-download-files-new.png" alt="Файлы готовы к скачиванию">

        <div class="cabinet-files__link links-block">
          <a v-if="files.bill" class="links-block__link btn" target="_blank" :href="files.bill">Скачать счет</a>
          <a v-if="files.ttn" class="links-block__link btn" target="_blank" :href="files.ttn">Скачать ТТН</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    files() {
      console.log(this.$store.getters.orderFiles);
      return this.$store.getters.orderFiles;
    }
  },
  methods: {
    async getFiles() {
      await this.$store.dispatch({type:'getFiles', id:this.$route.params.id})
    }
  },
  created() {
    this.getFiles();
  },
}
</script>

