<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="error">
          <p class="error__text">
            Технические неполадки<br>
            Попробуйте выполнить действие еще раз.
          </p>

          <router-link to="/" class="error__btn btn" href="#">Вернуться на главную</router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  components: {},
  methods: {},
}
</script>

