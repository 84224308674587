import axios from "axios";
export default {
    state: {
        orderProps: false,
        orderInfo: [],
        orders: [],
        order: [],
        orderFiles: [],
        orderMin: 0
    },
    mutations: {
        orderProps(state, props) {
            state.orderProps = props;
        },
        orderInfo(state, order) {
            state.orderInfo[order.id] = order;
        },
        orderDetail(state, order) {
            state.order = order;
        },
        orders(state, orders) {
            state.orders = orders;
        },
        orderFiles(state, orderFiles) {
            state.orderFiles = orderFiles;
        },
        orderMin(state, orderMin) {
            state.orderMin = orderMin;
        }
    },
    getters: {
        orderProps: s => s.orderProps,
        orderInfo: (state) => (id) => {
            return state.orderInfo[id];
        },
        orders: s => s.orders,
        orderDetail: s => s.order,
        orderFiles: s => s.orderFiles,
        orderMin: s => s.orderMin
    },
    actions: {
        async fetchOrderProps({dispatch, commit}) {
            let response = await axios('/local/api/order/fields').then(function (response) {
                let props = response.data; // читать тело ответа в формате JSON
                commit('orderProps', props);
            }); // завершается с заголовками ответа

        },
        async fetchOrderInfo({dispatch, commit}, data) {
            let response = await axios('/local/api/order/info/'+data.id).then(function (response) {
                let orderInfo = response.data; // читать тело ответа в формате JSON
                commit('orderInfo', orderInfo.item);
            }); // завершается с заголовками ответа

        },
        async fetchOrders({dispatch, commit}) {
            let response = await axios('/local/api/order/list').then(function (response) {
                let orders = response.data; // читать тело ответа в формате JSON
                commit('orders', orders);
            }); // завершается с заголовками ответа

        },
        async fetchOrderDetail({dispatch, commit}, data) {
            let response = await axios('/local/api/order/detail/'+data.id).then(function (response) {
                let orderDetail = response.data; // читать тело ответа в формате JSON
                commit('orderDetail', orderDetail.item);
            }); // завершается с заголовками ответа

        },
        async clearOrderDetail({dispatch, commit, state}) {
            state.order = [];
        },
        async getFiles({dispatch, commit}, data) {
            let response = await axios('/local/api/order/files/'+data.id).then(function (response) {
                let orderFiles = response.data; // читать тело ответа в формате JSON
                commit('orderFiles', orderFiles.item);
            }); // завершается с заголовками ответа

        },
        async fetchOrderMin({dispatch, commit}) {
            let response = await axios('/local/api/order/ordermin').then(function (response) {
                let orderMin = response.data; // читать тело ответа в формате JSON
                commit('orderMin', orderMin);
            }); // завершается с заголовками ответа

        },
    }
}
