import Catalog from "./views/Catalog.vue";
import Main from './views/Main.vue'
import MainNotAuth from './views/MainNotAuth.vue'
import Login from './views/Login.vue'
import Notfound from './views/Notfound.vue'
import Error from './views/Error.vue'
import Registration from './views/Registration.vue'
import RegistrationComplete from './views/RegistrationComplete.vue'
import ForgetPassword from './views/ForgetPassword.vue'
import NewPassword from './views/NewPassword.vue'
import About from './views/About.vue'
import Personal from './views/Personal.vue'
import Profile from './views/Profile.vue'
import Orders from './views/Orders.vue'
import Refund from './views/Refund.vue'
import ChangePassword from './views/ChangePassword.vue'
import Favorite from './views/Favorite.vue'
import Section from './views/Section.vue'
import Sale from './views/Sale.vue'
import New from './views/New.vue'
import Detail from './views/Detail.vue'
import Basket from './views/Basket.vue'
import Order from './views/Order.vue'
import OrderComplete from './views/OrderComplete.vue'
import Reconciliation from './views/Reconciliation.vue'
import OrderDetail from './views/OrderDetail.vue'
import RefundDetail from './views/RefundDetail.vue'
import RefundAdd from './views/RefundAdd.vue'
import OrderDocs from './views/OrderDocs.vue'
import Delete from './views/Delete.vue'

import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import store from './store'

const ifNotAuthenticated = (to, from, next) => {
    next();
    return;
    if (!store.getters.isAuth) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    next();
    return;
    if (from.name == undefined) {
        next();
        return;
    }
    if (store.getters.isAuth) {
        next()
        return
    }
    next('/main-not-auth')
}

export default new createRouter({
    mode: 'history',
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    //base: process.env.BASE_URL,
    routes: [
        {
            path: '/main-not-auth',
            component: MainNotAuth,
            name:'main-not-auth',
            meta: {title: 'Главная'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/delete',
            component: Delete,
            name:'delete',
            meta: {title: 'Удалить аккаунт'},
            beforeEnter: ifAuthenticated
        },
        {
            path: '/login',
            component: Login,
            name:'login',
            meta: {title: 'Авторизация'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/registration',
            component: Registration,
            name:'registration',
            meta: {title: 'Регистрация'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/registration-complete',
            component: RegistrationComplete,
            name:'registration-complete',
            meta: {title: 'Заявка принята'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/forget-password',
            component: ForgetPassword,
            name:'forget-password',
            meta: {title: 'Восстановление пароля'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/new-password',
            component: NewPassword,
            name:'new-password',
            meta: {title: 'Новый пароль'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/about',
            component: About,
            name:'about',
            meta: {title: 'Информация о компании'},
        },
        {
            path: '/catalog',
            component: Catalog,
            name:'catalog',
            meta: {layout: 'main', title: 'Каталог', bigTitle: true} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/catalog/:id',
            component: Section,
            name:'section',
            meta: {layout: 'main', title: 'Каталог'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/sale',
            component: Sale,
            name:'sale',
            meta: {layout: 'main', title: 'Распродажа'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/new',
            component: New,
            name:'new',
            meta: {layout: 'main', title: 'Новинки'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/detail/:id',
            component: Detail,
            name:'detail',
            meta: {layout: 'main', title: 'Каталог'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/basket',
            component: Basket,
            name:'basket',
            meta: {layout: 'main', title: 'Корзина'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/order-complete/:id',
            component: OrderComplete,
            name:'order-complete',
            meta: {layout: 'main', title: 'Заказ принят'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/order',
            component: Order,
            name:'order',
            meta: {layout: 'main', title: 'Оформление заказа'} ,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/',
            component: Main,
            name:'main',
            meta: {layout: 'main', showLogo: true},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/personal',
            component: Personal,
            name:'personal',
            meta: {layout: 'main', title: 'Личный кабинет'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/profile',
            component: Profile,
            name:'profile',
            meta: {layout: 'main', title: 'Мои данные'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orderdetail/:id',
            component: OrderDetail,
            name:'order_detail',
            meta: {layout: 'main', title: 'Заказ'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orders',
            component: Orders,
            name:'orders',
            meta: {layout: 'main', title: 'Мои заказы'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/refund/:id',
            component: RefundDetail,
            name:'refund-detail',
            meta: {layout: 'main', title: 'Запрос на возврат'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/refund',
            component: Refund,
            name:'refund',
            meta: {layout: 'main', title: 'Запросы на возврат'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/refund/add/:id',
            component: RefundAdd,
            name:'refund-add',
            meta: {layout: 'main', title: 'Подать запрос на возврат'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orderdocs/:id',
            component: OrderDocs,
            name:'orderdocs',
            meta: {layout: 'main', title: 'Файлы'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/change-password',
            component: ChangePassword,
            name:'change-password',
            meta: {layout: 'main', title: 'Изменить пароль'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/favorite',
            component: Favorite,
            name:'favorite',
            meta: {layout: 'main', title: 'Избранное'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/reconciliation',
            component: Reconciliation,
            name:'reconciliation',
            meta: {layout: 'main', title: 'Запросить акт сверки'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/500',
            component: Error,
            name:'error',
            meta: {layout: 'empty', title: 'Ошибка'}
        },
        {
            path: '/:pathMatch(.*)*',
            component: Notfound,
            name:'notfound',
            meta: {layout: 'empty', title: 'Ошибка'}
        }
    ]
})