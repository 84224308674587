<template>
  <main class="main">
    <section v-if="user" class="section">
      <div class="site-container">
        <a v-if="dogovor" class="document" :href="dogovor.src">
          <span class="document__name">{{ dogovor.name }}</span>
          <span class="document__size">{{ dogovor.size }}</span>
        </a>

        <div v-show="success" class="info-block info-block--success">
          <p>{{ success }}</p>
        </div>

        <div v-show="edit" class="info-block info-block--success">
          <p>Данные отправлены на модерацию</p>
        </div>

        <div v-show="error" class="info-block info-block--alert">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>

        <form class="form form--cabinet" action="#" method="post" @submit.prevent="save">
          <div class="form__input-group">
            <h2 class="form__input-group-heading form__input-group-heading--required">Обязательные для заполнения поля</h2>
            <div class="form__input-block">
              <label class="form__input-label">
                Наименование точки продажи<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="name" required class="form__input input" type="text" placeholder="Введите название бара/иного заведения">
              </div>
            </div>

            <div class="form__input-block">
              <label class="form__input-label">
                ИНН<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="inn" required class="form__input input" type="number" placeholder="Введите данные">
              </div>
            </div>
          </div>
          <div class="form__input-group">
          <template v-for="(item, index) in priem" :key="item.id">

              <button v-if="index>0" class="form__input-group-remove" type="button" @click="deletePoint(index)" :key="item.id">Удалить</button>
              <div class="form__input-block">
                <label class="form__input-label">
                  Адрес точки продажи<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="item.address" required :key="item.id" class="form__input form__input--textarea textarea textarea--small" placeholder="Введите данные"></textarea>
                </div>
              </div>

              <div v-if="false" class="form__input-block">
                <label class="form__input-label">
                  Комментария к адресу <span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="comment_address" required :key="item.id" class="form__input form__input--textarea textarea textarea--small" placeholder="Введите данные"></textarea>
                </div>
              </div>

          </template>

          <div class="form__input-block">
            <label class="form__input-label">
              Время приёма отгрузок<span class="form__input-required"> *</span>
            </label>
            <div class="form__input-wrapper">
              <Select2 class="form__input--half" v-model="priem_from" :options="from_options"  @select="mySelectEvent($event)" placeholder="с --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

              <Select2 class="form__input--half" v-model="priem_to" :options="to_options" placeholder="до --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

            </div>
          </div>
          </div>

          <div class="form__input-group">

            <div class="form__input-block">
              <label class="form__input-label" for="formInput5">
                ФИО контактного лица<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="fio" required class="form__input input" type="text" name="FIO" id="formInput5" placeholder="Введите данные">
              </div>
            </div>

            <div class="form__input-block">
              <label class="form__input-label">
                Контактный телефон<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <InputMask  v-model="phone" mask="+7 (999) 999-99-99" required class="form__input input" type="text" placeholder="+7 (___) ___-__-__" />
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--required form__input-group--no-border">
            <h2 class="form__input-group-heading form__input-group-heading--required">Данные для входа (обязательные)</h2>

            <div class="form__input-block">
              <label class="form__input-label" for="formInput7">
                E-mail<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input class="form__input input" type="email"  v-model="email" required id="formInput7"  placeholder="Введите данные">
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required">
            <h2 class="form__input-group-heading form__input-group-heading--non-required">Необязательные поля</h2>

            <button class="form__group-toggle btn btn--non-target" type="button"
                    :class="tabur?'form__group-toggle--open':''"
                    @click="tabur=!tabur"
            >Данные юридического лица</button>

            <div class="form__group-minimized" v-show="tabur" style="display: none;">
              <div class="form__input-block">
                <label class="form__input-label" for="formInput120">
                  Наименование юридического лица
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="company" class="form__input form__input--textarea textarea textarea--small" name="YUR_NAME" id="formInput120" placeholder="Введите данные"></textarea>
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput12">
                  Юридический адрес
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="uraddress" class="form__input form__input--textarea textarea textarea--small" name="YUR_ADDRESS" id="formInput12" placeholder="Введите данные"></textarea>
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput13">
                  КПП
                </label>
                <div class="form__input-wrapper">
                  <input v-model="kpp" class="form__input input" type="text" name="KPP" id="formInput13" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput14">
                  ОГРН
                </label>
                <div class="form__input-wrapper">
                  <input v-model="ogrn" class="form__input input" type="text" name="OGRN" id="formInput14" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput15">
                  ОКПО
                </label>
                <div class="form__input-wrapper">
                  <input v-model="okpo" class="form__input input" type="text" name="OKPO" id="formInput15" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput16">
                  ID ЕГАИС
                </label>
                <div class="form__input-wrapper">
                  <input v-model="egais" class="form__input input" type="text" name="ID_EGAIS" id="formInput16" placeholder="Введите данные">
                </div>
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required">
            <button class="form__group-toggle btn btn--non-target" type="button"
                    :class="tabbill?'form__group-toggle--open':''"
                    @click="tabbill=!tabbill"
            >Расчетный счет</button>

            <div class="form__group-minimized" v-show="tabbill" style="display: none;">
              <div class="form__input-block">
                <label class="form__input-label" for="formInput17">
                  БИК
                </label>
                <div class="form__input-wrapper">
                  <input v-model="bik" class="form__input input" type="text" name="BIK" id="formInput17" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput18">
                  Банк
                </label>
                <div class="form__input-wrapper">
                  <input v-model="bank" class="form__input input" type="text" name="BANK" id="formInput18" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput19">
                  К/с
                </label>
                <div class="form__input-wrapper">
                  <input v-model="ks" class="form__input input" type="text" name="KS" id="formInput19" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput20">
                  Р/с
                </label>
                <div class="form__input-wrapper">
                  <input v-model="rs" class="form__input input" type="text" name="RS" id="formInput20" placeholder="Введите данные">
                </div>
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required">
            <button class="form__group-toggle btn btn--non-target" :class="tabdocs?'form__group-toggle--open':''" type="button" @click="tabdocs=!tabdocs">Документы</button>

            <div class="form__group-minimized" v-show="tabdocs">
              <div class="form__input-block">
                <input class="form__input form__input--file hidden-input" type="file" id="files" ref="files" multiple v-on:change="handleFileUploads( $event )">
                <a v-for="(file, index) in uf_files" class="form__uploaded-file" :href="file.src">{{file.name}}</a>
                <a v-for="(file, index) in files" :key="file.name" class="form__uploaded-file">
                  {{ file.name }}<span @click="delFile(file.name)"></span></a>
                <label class="form__input-label form__input-label--file form__add-btn form__add-btn--files btn btn--non-target" for="files">
                  Загрузить файлы
                </label>
                <div class="form__files-text">
                  <p>
                    Загрузите файлы, которые у вас есть. Желательный список документов:
                  </p>
                  <ol>
                    <li>карта контрагента (с обязательным указанием юридического адреса и фактического),</li>
                    <li>свидетельство ОГРН (ОГРИП), </li>
                    <li>свидетельство о постановке на учёт в налоговый орган (ИНН),</li>
                    <li>документы подтверждающие полномочия на подписания договора (доверенность, приказ на директора).</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required form__input-group--no-border">
            <div class="form__input-block">
              <label class="form__input-label" for="formInput22">
                Комментарий
              </label>
              <div class="form__input-wrapper">
                <textarea v-model="comment" class="form__input form__input--textarea textarea" name="COMMENT" id="formInput22" placeholder="Напишите, что нам нужно знать"></textarea>
              </div>
            </div>

            <button v-if="!edit" class="form__submit-btn btn" type="submit" id="cabinetFormSaveBtn">Сохранить изменения</button>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
import InputMask from 'primevue/inputmask';

export default {
  data: () => ({
    success: false,
    edit: false,
    email: '',
    password: '',
    password_confirm: '',
    phone: '',
    fio: '',
    inn: '',
    name: '',
    address: '',
    uraddress: '',
    ogrn: '',
    okpo: '',
    bik: '',
    bank: '',
    ks: '',
    rs: '',
    egais: '',
    comment: '',
    addres_commnet: '',
    kpp: '',
    company: '',
    priem_from: '',
    priem_to: '',
    priem: [
      {
        id: 'n'+Date.now(),
        address: '',
        from: '',
        to: ''
      }
    ],
    files: [],
    uf_files: [],
    dogovor: false,
    error: false,
    tabdocs: false,
    tabur: false,
    tabbill: false,
    agree_offerta: false,
    agree_politics: false,
    from_options: ['10:00', '11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00'],
    to_options_orig: ['11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00', '22:00'],
    to_options: ['11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00', '22:00'] // or [{id: key, text: value}, {id: key, text: value}]
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    user() {
      let userInfo = this.$store.getters.userInfo.item;
    if (userInfo) {
      this.name = userInfo.name
      this.email = userInfo.email;
      this.phone = userInfo.personal_phone;
      this.fio = userInfo.name;
      this.inn = userInfo.uf_inn;
      this.address = userInfo.email;
      this.uraddress = userInfo.uf_uraddress;
      this.ogrn = userInfo.uf_ogrn;
      this.okpo = userInfo.uf_okpo;
      this.bik = userInfo.uf_bik;
      this.bank = userInfo.uf_bank;
      this.ks = userInfo.uf_ks;
      this.rs = userInfo.uf_rs;
      this.egais = userInfo.uf_egais;
      this.comment = userInfo.uf_comment;
      this.comment_address = userInfo.uf_comment_address;
      this.kpp = userInfo.uf_kpp;
      this.company = userInfo.uf_company;
      this.priem = userInfo.store;
      this.uf_files = userInfo.uf_docs;
      this.priem_from = userInfo.uf_priem_from;
      this.priem_to = userInfo.uf_priem_to;
      this.dogovor = userInfo.dogovor;
      this.edit = userInfo.edit;
    }
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async userInfo() {
      await this.$store.dispatch('fetchUserInfo')

    },
    save() {
      this.error = false;
      this.success = false;

      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('personal_phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('inn', this.inn);
      formData.append('name', this.name);
      formData.append('kpp', this.kpp);
      formData.append('ogrn', this.ogrn);
      formData.append('okpo', this.okpo);
      formData.append('egais', this.egais);
      formData.append('bik', this.bik);
      formData.append('bank', this.bank);
      formData.append('ks', this.ks);
      formData.append('rs', this.rs);
      formData.append('uraddress', this.uraddress);
      formData.append('company', this.company);
      formData.append('comment', this.comment);
      formData.append('priem_from', this.priem_from);
      formData.append('priem_to', this.priem_to);
      //formData.append('comment_address', this.comment_address);
      for( var i = 0; i < this.priem.length; i++ ){
        let priem = this.priem[i];
        formData.append('priem[' + i + '][id]', priem.id);
        formData.append('priem[' + i + '][address]', priem.address);
        formData.append('priem[' + i + '][from]', priem.from);
        formData.append('priem[' + i + '][to]', priem.to);
      }
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        formData.append('docs[' + i + ']', file);
      }
      let _this = this;
      axios.post( '/local/api/personal/profile/save',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          window.scrollTo(0, document.querySelector(".site-container").offsetTop);
        }
        if (res.data.status == 200) {
          _this.success = res.data.text;
          window.scrollTo(0, document.querySelector(".site-container").offsetTop);
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
    handleFileUploads(event){
      for( var i = 0; i < event.target.files.length; i++ ){
        if (typeof event.target.files[i] == 'object') {
          this.files.push(event.target.files[i]);
        }
      }
    },
    delFile(name){
      for( var i = 0; i < this.files.length; i++ ){
        if (typeof this.files[i] == 'object' &&  this.files[i].name == name) {
          this.files.splice(i, 1);
        }
      }
    },
    addPoint() {
      this.priem.push({
        id: 'n'+Date.now(),
        address: '',
        from: '',
        to: ''
      });
    },
    deletePoint(index) {
      this.priem.splice(index, 1);
      console.log(this.priem)
    },
    mySelectEvent(event) {
      let to_options = [];
      for (let item in this.to_options_orig) {
        if (this.to_options_orig[item] > event.text === true)
          to_options.push(this.to_options_orig[item])
      }
      this.to_options = to_options;
    }
  },
  created() {
    this.userInfo();
  },
  components: {Select2, InputMask}
}
</script>

