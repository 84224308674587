<template>
  <section class="section">
    <div class="site-container">
      <div class="block-links block-links--new" v-if="sections">
        <div class="block-links__section" v-for="section in sections">
          <h2 class="block-links__section-heading">{{ section.section }}</h2>
          <div class="block-links__subsections-list">
            <router-link
              v-for="item in section.subsections"
              class="block-links__link"
              :to="item.id"
              :style="'background-image: url('+item.image+');'"
            >
              <div class="block-links__link-text">
                <span class="block-links__link-heading">{{ item.section }}</span>
                <span class="block-links__link-count">{{ item.quantity }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else>Загрузка...</div>
    </div>
  </section>
</template>

<script>

export default {
  beforeRouteLeave(to, from) {
    this.$store.dispatch('unLoader');
  },
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    sections() {
      console.log(this.$store.getters.sections);
      return this.$store.getters.sections;
    }
  },
  methods: {
    async showSections() {
      await this.$store.dispatch('fetchSections')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showSections();
  },
}
</script>

