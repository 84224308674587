<template>
    <component :is="layout" v-if="isLoad">
      <router-view/>
    </component>
    <div class="preloader preloader--loading" v-if="!loader"></div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import axios from 'axios'
export default {
  data: () => ({
    myvar: 'test',
    device_uuid: '',
    device_token: '',
    isLoad: false,
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    loader() {
      return this.$store.getters.loader;
    },
    mytoken() {
      return this.$attrs.mytoken
    },
  },
  components: {
    EmptyLayout, MainLayout
  },
  async created() {
    let device_uuid = this.$attrs.device_uuid;
    this.device_uuid = device_uuid;
    this.$store.dispatch('Uuid', device_uuid)
    let _this = this;
    await axios.get('/local/api/auth').then(function (response) {
      let auth = response.data;
      if (auth.is_auth === false) {
        _this.$router.push('/main-not-auth');
       // window.location.href = '/main-not-auth';
      }
      _this.$store.dispatch('Auth', {
       // login: auth.arUser.LOGIN,
        user: auth.arUser,
        phone: auth.phone,
        token: auth.token,
        uuid: auth.uuid,
        is_auth: auth.is_auth
      })

      _this.axios.interceptors.response.use(function (response) {
        if (response.data.auth == false) {
          _this.$router.push('/main-not-auth');
        }
        // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
        // Здесь можете сделать что-нибудь с ответом
        return response;
      }, function (error) {
        console.log(error);
        if (error.code == 'ERR_NETWORK') {
          _this.$router.push('/404');
        }
        else if (error.code == 'ERR_BAD_RESPONSE') {
          _this.$router.push('/500');
        }
        else if (error.response.status == 401) {
          _this.$router.push('/main-not-auth');
          //window.location.href = '/main-not-auth';
        }
        else if (error.response.status == 404) {
          _this.$router.push('/404');
          //window.location.href = '/main-not-auth';
        }
        // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
        // Здесь можете сделать что-то с ошибкой ответа
        return Promise.reject(error);
      });
    }).catch(function(){

    });
    /*if (typeof result == 'object' && typeof result.data == 'object') {
      let auth = result.data;
     // if (auth.is_auth === true) {
        this.$store.dispatch('Auth', {
          login: auth.arUser.LOGIN,
          user: auth.arUser,
          token: auth.token,
          uuid: auth.uuid,
          is_auth: auth.is_auth
        })
     // }
    }*/
    //console.log(result)

    if (typeof window.FirebasePlugin != 'undefined') {
      /*window.FirebasePlugin.getToken(async function (device_token) {
        axios.post('/local/api/token', {
          token: device_token
        });
      }, function (error) {
        //alert('error')
      });*/
      /*if (typeof window.device == 'object' && window.device.platform.indexOf("iOS") != -1 && window.device.version >= '14.5') {
        this.showAppTrackingTransparency();
      } else {
        // Show custom message
      }*/
      //Register handlers
      window.FirebasePlugin.onMessageReceived(function (message) {
        try {
          console.log("onMessageReceived");
          console.log(message);
          if (message.url && message.tap == 'background') {
            _this.$router.push(message.url)
          }
          if (message.messageType === "notification") {
            //console.log(message);
          } else {
            //console.log(message);
          }
        } catch (e) {
          console.log("Exception in onMessageReceived callback: " + e.message);
        }

      }, function (error) {
        console.log("Failed receiving FirebasePlugin message", error);
      });
    }
   // if (this.$store.getters.isAuth === false)
   //   this.$router.push('/main-not-auth');
    this.isLoad = true;
  },
  methods: {
    showAppTrackingTransparency() {
      const idfaPlugin = window.cordova.plugins.idfa;
      idfaPlugin.getInfo().then((info) => {
        if (!info.trackingLimited) {
          return info.idfa || info.aaid;
        } else if (
            info.trackingPermission ===
            idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
        ) {
          return idfaPlugin.requestPermission().then((result) => {
            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {

              // Start your tracking plugin here!

              return idfaPlugin.getInfo().then((info) => {
                return info.idfa || info.aaid;
              });
            }
          });
        }
      });
    }
  }
}
</script>