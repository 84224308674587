<template>

    <section v-if="product" class="section">
      <div class="site-container">
        <div class="catalog-element">
          <div class="catalog-element__main-info">
            <div class="catalog-element__top-info">
              <div class="catalog-element__img-block" v-if="product.image">
                <img class="catalog-element__img" :src="product.image" :alt="product.name">
              </div>

              <div class="catalog-element__props-block">
                <div class="catalog-element__tags">
                  <span v-if="product.new" class="catalog-element__tag catalog-element__tag--new">NEW</span>
                  <span v-if="product.sale" class="catalog-element__tag catalog-element__tag--sale">SALE</span>
                </div>

                <div class="catalog-element__props">
                  <p v-if="product.artnumber" class="catalog-element__prop">Арт. {{product.artnumber}}</p>

                  <p v-if="product.stil" class="catalog-element__prop">
                    Стиль <span class="catalog-element__prop-value">{{product.stil}}</span>
                  </p>

                </div>

                <div class="catalog-element__number-props">
                  <p v-if="product.abv" class="catalog-element__number-prop">
                    <span class="catalog-element__number-prop-value">{{product.abv}}</span>
                    <span class="catalog-element__number-prop-name">ABV</span>
                  </p>

                  <p v-if="product.og" class="catalog-element__number-prop">
                    <span class="catalog-element__number-prop-value">{{product.og}}</span>
                    <span class="catalog-element__number-prop-name">OG</span>
                  </p>

                  <p v-if="product.ibu" class="catalog-element__number-prop">
                    <span class="catalog-element__number-prop-value">{{product.ibu}}</span>
                    <span class="catalog-element__number-prop-name">IBU</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="catalog-element__bottom-info">
              <h2 class="catalog-element__name">{{product.name}}</h2>

              <div class="catalog-element__brand">
                <div v-if="product.brand.image" class="catalog-element__brand-img-block">
                  <img class="catalog-element__brand-img" :src="product.brand.image">
                </div>

                <span class="catalog-element__brand-name">{{ product.brand.name }}</span>
              </div>

              <div class="catalog-element__untappd">
                <div class="catalog-element__untappd-rating" v-if="false">
                  {{product.untappd}}
                </div>

                <a :href="product.untappd_link" v-if="product.untappd_link" class="catalog-element__untappd_button">Смотреть на <img src="/img/untappd_link_img.svg"></a>
              </div>
            </div>
          </div>

          <p class="catalog-element__prices">
            <span class="catalog-element__price">{{ product.price }}&nbsp;₽/шт.</span>
            <span v-if="product.old_price > product.price" class="catalog-element__price catalog-element__price--old">{{ product.old_price }}&nbsp;₽/шт.</span>
          </p>

          <div class="catalog-element__controls">
            <div class="catalog-element__controls-main">
              <button v-if="product.quantity > 0 && basket == 0" @click="changeQuantity('plus')" class="catalog-element__buy-btn btn">Добавить в корзину</button>
              <div v-if="product.quantity > 0 && basket > 0" class="product__controls-btns" style="width: 100%;">
                <button @click="changeQuantity('minus')" class="product__controls-btn product__controls-btn--minus" :class="{'product__controls-btn--disabled':basket==0}"></button>
                <input v-model="quantity" class="product__controls-counter" type="number" disabled="">
                <button @click="changeQuantity('plus')" class="product__controls-btn product__controls-btn--plus" :class="{'product__controls-btn--disabled':(product.quantity <= basket)}"></button>
              </div>
              <button v-if="product.quantity > 0 && basket > 0" @click="changeQuantityFive" class="product__controls-plus-five" :class="{'product__controls-plus-five--disabled':(product.quantity <= basket)}"></button>
            </div>


            <button @click="favorite" class="catalog-element__fav-btn btn btn--non-target" :class="{'catalog-element__fav-btn--active':product.favorite}"></button>
          </div>
          <p v-if="quantity>0" class="catalog-element__prices">
            <span class="catalog-element__price">{{ product.price*quantity }}&nbsp;₽</span>
          </p>
          <div style="text-align: center" v-if="product.quantity > 0 && product.quantity <= quantity" class="product__alert-block">
            <span>Добавлено максимальное количество</span>
          </div>

          <div class="catalog-element__additional-info">
            <div class="catalog-element__additional-info-block">
              <h3 class="catalog-element__additional-info-heading">Характеристики</h3>

              <div class="catalog-element__additional-info-chars">
                <p class="catalog-element__additional-info-char">
                  <span class="catalog-element__additional-char-name">Стиль</span>
                  <span class="catalog-element__additional-char-divider"></span>
                  <span class="catalog-element__additional-char-value">{{product.stil}}</span>
                </p>

                <p class="catalog-element__additional-info-char">
                  <span class="catalog-element__additional-char-name">Производитель</span>
                  <span class="catalog-element__additional-char-divider"></span>
                  <span class="catalog-element__additional-char-value">{{product.brand.name}}</span>
                </p>

                <p class="catalog-element__additional-info-char">
                  <span class="catalog-element__additional-char-name">Объем</span>
                  <span class="catalog-element__additional-char-divider"></span>
                  <span class="catalog-element__additional-char-value">{{product.volume_t2}}</span>
                </p>

                <p class="catalog-element__additional-info-char">
                  <span class="catalog-element__additional-char-name">Тара</span>
                  <span class="catalog-element__additional-char-divider"></span>
                  <span class="catalog-element__additional-char-value">{{product.tip_upakovki}}</span>
                </p>

                <p class="catalog-element__additional-info-char">
                  <span class="catalog-element__additional-char-name">Наим. ЕГАИС</span>
                  <span class="catalog-element__additional-char-divider"></span>
                  <span class="catalog-element__additional-char-value">{{product.egais}}</span>
                </p>

                <p v-if="false" class="catalog-element__additional-info-char">
                  <span class="catalog-element__additional-char-name">Код ЕГАИС</span>
                  <span class="catalog-element__additional-char-divider"></span>
                  <span class="catalog-element__additional-char-value">{{product.egais}}</span>
                </p>
              </div>
            </div>

            <div class="catalog-element__additional-info-block">
              <h3 class="catalog-element__additional-info-heading">Описание</h3>

              <div class="catalog-element__additional-info-desc">
                <p v-html="product.description">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

</template>

<script>

export default {
  data: () => ({
    quantity: false,
    stop_quantity_computed: false
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    product() {
      return this.$store.getters.product;
    },
    basket() {
      let basket_quantity = this.$store.getters.inBasket(this.product.id);
      if (this.stop_quantity_computed === false)
        this.quantity = basket_quantity;
      return basket_quantity;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    basket(val) {
      /*if (val != this.quantity)
        this.quantity = val;*/
    }
  },
  methods: {
    async showProduct() {
      await this.$store.dispatch({type:'fetchProduct', id:this.$route.params.id})
    },
    favorite() {
      if (!this.user.ID) {
        this.$router.push("/login");
        return;
      }
      if (this.product.favorite) {
        this.$store.dispatch({type:'deleteFavorite', id: this.product.id})
      }
      else {
        this.$store.dispatch({type:'addFavorite', id: this.product.id})
      }
      this.product.favorite = !this.product.favorite;
    },
    changeQuantity(type) {
      if (!this.user.ID) {
        this.$router.push("/login");
        return;
      }
      this.stop_quantity_computed = true;
      let act = '';
      let quantity = this.quantity;
      if (type == 'plus') {
        if (this.product.quantity <= this.quantity)
          return;
        if (quantity == 0)
          act = 'add';
        else
          act = 'update';
        quantity++;
      }
      else {
        if (quantity > 1) {
          act = 'update';
        }
        else if (quantity == 1) {
          act = 'delete';
        }
        if (act)
          quantity--;
      }
      this.quantity = quantity;
      if (act == 'add')
        this.$store.dispatch({type:'addBasket', id: this.product.id, quantity: quantity})
      else if (act == 'update')
        this.$store.dispatch({type:'updateBasket', id: this.product.id, quantity: quantity})
      else if (act == 'delete')
        this.$store.dispatch({type:'deleteBasket', id: this.product.id, quantity: quantity})
    },
    changeQuantityFive() {
      if (this.product.quantity <= this.quantity)
        return;
      let quantity = this.quantity;
      for (let i = 0; i < 5; i++) {
        if (this.product.quantity > this.quantity) {
          quantity++;
          this.quantity = quantity;
        } else {
          break;
        }
      }
      this.$store.dispatch({type:'updateBasket', id: this.product.id, quantity: quantity})
    }
  },
  created() {
    this.$store.dispatch('clearProduct');
    this.showProduct();
  },
  components: {

  }
}
</script>

